.navigation-container ul {
    display: flex;
}

.navigation-container ul li {
    list-style-type: none;
    padding: 0px 5px;
}

.navigation-container ul li a {
    text-decoration: none;
}

.nav-row {
    display: flex;
    justify-content: space-between;
}

.logo-container {
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 10px;
}

.logo-container img {
    height: 30px;
}